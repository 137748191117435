import axios from '../api/axios';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import translations from '../translations';

const FILL_QUESTIONNAIRE_URL = process.env.REACT_APP_BASE_URL + "/api/not_feeling_well/";

export const uploadingFeelingWell = async (answers, setErrMsg, navigate , setCurrentQuestionIndex) => {
    const { activeLang , setResults, setIsFileUploaded, setLoading , setSuccessMsg , setSignInForVisitor, setUploadAgain} = useData.getState();
    const lang = translations[activeLang];
    
    console.log(JSON.stringify(answers, null, 2));
    setLoading(true);
    try {
        const response = await axios.post(FILL_QUESTIONNAIRE_URL, 
            {
                history: JSON.stringify(answers, null, 2),
            }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        });
        if (response.status === 200) {
            toast.success('Your Results is ready !!!');
            setResults(response.data);
            navigate('/Results');
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status === 403) {
                setLoading(false);
                setSignInForVisitor(true);
                toast.error(lang.UserNotLoggedIn);
                return ;
            } else if (error.response.status === 400) {
                setLoading(false);
                toast.error(error.message);
            }
        } else {
            setLoading(false);
            console.log(error);
            toast.error(lang.NetworkError);
        }
    }
};
